@import url("https://fonts.googleapis.com/css2?family=Inter:wght@500;600;700;800;900&display=swap");

body {
  width: 100vw;
  background-color: black;
  margin: -25px;
  font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::selection {
  background: white;
  color: black;
}

@media only screen and (max-width: 1200px) {
  body {
    width: 100vw;
  }
}

a {
  text-decoration: none !important;
}
